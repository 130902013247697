.video {
    width: 100%;
    height: 100%;
    display: flex;
    margin: auto;
}

.main {
    display: flex;
    margin: auto;
    width: 768px !important;
    height: 432px !important;
}

.tel {
    position: absolute;
    bottom: 5%;
    display: flex;
    width: 100%;
    color: white;
    font-size: 25px;
}

.tel-btn {
    margin: auto;
    padding: 15px;
    border: 2px solid #D0B49F;
    background-color: black;
    border-radius: 50px;
    transition: all 0.3s;
    color: #D0B49F;
}

.tel-btn:hover {
    transform: scale(1.2, 1.2);
}

@media screen and (max-width:800px) {
    .main {
        width: 100% !important;
        height: 100% !important;
    }
}